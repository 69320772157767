// Show hide columns list
.custom-mat-select-list{
  --mdc-list-list-item-label-text-size: 0.75rem;
  --mdc-list-list-item-label-text-tracking: 0.025em;
  --mdc-list-list-item-one-line-container-height: 1.75rem;
  background: var(--white);
  position: relative;
  z-index: 5;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  transform-origin: right top;

  &.mdc-list {
    padding: 0;
  }

  .mdc-list-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &:not(:last-of-type){
      border-bottom: 1px solid var(--border-color);
    }
  }

  .mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
    margin-left: 0.75rem;
    margin-right: 0px;
  }

  .mat-mdc-list-option .mdc-checkbox {
    --mdc-checkbox-state-layer-size: 1.75rem;

    .mdc-checkbox__background {
      width: 0.875rem;
      height: 0.875rem;
      top: calc((var(--mdc-checkbox-state-layer-size, 40px) - 0.875rem) / 2);
      left: calc((var(--mdc-checkbox-state-layer-size, 40px) - 0.875rem) / 2);
    }
  }
}


.custom-mat-multi-line-select-list{
  &.mat-mdc-list{
    --mdc-list-list-item-one-line-container-height: auto;
    padding: 0;

    .mat-mdc-list-item {
      padding: 0;
    }
  }
}
