@use "/src/assets/scss/base/" as b;

.custom-dialog {

  .mat-mdc-dialog-container{
    padding: 0px;

    .mat-mdc-dialog-surface{
      overflow-x: hidden;
      overflow-y: hidden;
    }

    .mat-mdc-dialog-title {
      margin: 0px;
      padding: 0.5rem 0.75rem;
      background: var(--primary-color);
      color: var(--light-color);
      min-height: 2.125rem;

      &::before {
        display: none;
      }

      .custom-dialog-header{
        @include b.flex(row, space-between, center);
        width: 100%;
        padding: 0.75rem 0;
        color: var(--light-color);

        img {
          height: 2.25rem;
          width: auto;
        }

        .custom-dialog-close-icon {
          font-weight: bolder;
          @include b.mat-icon(1.125rem, 1.125rem, 1.125rem, 1.125rem);
        }
      }
    }

    .mat-mdc-dialog-title+.mat-mdc-dialog-content {
      padding-top: 0.75rem;
    }

    .mat-mdc-dialog-content {
      padding: 0.625rem 1rem 0.5rem;
      margin: 0px;
      max-height: calc(100vh - 78px);

      &.special-content {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
    }

    .mat-mdc-dialog-actions {
      min-height: 2.75rem;
      margin-bottom: 0px;
      padding: 0 1rem;
      @include b.flex(row, center, center);
      column-gap: 1.25rem;
      border-top: 1px solid var(--page-borders);

      .mat-mdc-button-base {
        @include b.flex(row, center, center);
        font-size: 0.75rem;
        line-height: 1.75rem;
        height: 1.75rem;
        font-weight: 400;
        outline: none;
        padding: 0 0.625rem;

        .mdc-button__label{
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 0.375rem;
        }

        &.mat-primary {
          border: 1px solid var(--primary-color);
          color: var(--light-color);
        }

        &.mat-mdc-outlined-button {
          color: var(--page-text);
        }

        &.mat-warn {
          border: 1px solid var(--error-color);
        }

        &:disabled {
          background: var(--disabled-element-bg);
          border: 1px solid var(--disabled-element-borders);
          color: var(--disabled-element-text);
        }

        .mat-icon,
        .material-icons-outlined{
          --mat-filled-button-icon-spacing: 5px;
          width: 0.875rem;
          height: 0.875rem;
          font-size: 0.875rem;
          line-height: 0.875rem;
        }
      }

      .mat-mdc-button-base+.mat-mdc-button-base{
        margin-left: 0;
      }

      .buttonsWithSpinner {
        .mat-mdc-button-base{
          @include b.flex(row, center, center);
          font-size: 0.75rem;
          line-height: 1.75rem;
          height: 1.75rem;
          font-weight: 400;
          outline: none;
          padding: 0 0.625rem;
        }

        .mat-mdc-button-base+.mat-mdc-button-base{
          margin-left: 0;
        }

        &:disabled {
          background: var(--disabled-element-bg);
          border: 1px solid var(--disabled-element-borders);
          color: var(--disabled-element-text);
        }
      }

    }
  }

  &.no-actions-dialog{
    .mat-mdc-dialog-container{
      .mat-mdc-dialog-content {
        max-height: calc(100vh - 2.125rem);
      }
    }
  }

  .custom-dialog-title {
    @include b.flex(row, space-between, center);
    column-gap: 1rem;

    h3 {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 0;
      color: var(--light-color);
      font-weight: normal;

      &.text-bold{
        font-weight: bold;
        letter-spacing: 0.75px;
      }
    }

    .mat-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

