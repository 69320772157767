@use "/src/assets/scss/base/" as b;

/* *** Login form *** */
.login-form{
  .mat-mdc-form-field{
    width: 100%;
    --mat-form-field-container-height: 2.75rem;
    --mat-form-field-container-vertical-padding: 0.5rem;
    --mat-form-field-container-text-line-height: 1.75rem;
    --mat-form-field-container-text-size: 0.875rem;
    --mdc-outlined-text-field-label-text-size: 0.8125rem;
    --mat-form-field-outlined-label-text-populated-size: 0.875rem;
    --mdc-outlined-text-field-container-shape: 0.375rem;
    --mat-form-field-subscript-text-line-height: 1.625rem;
    --mat-form-field-subscript-text-size: 0.75rem;
    // Text colors
    --mdc-outlined-text-field-input-text-color: var(--light-color);
    --mdc-outlined-text-field-focus-input-text-color: var(--light-color);
    --mdc-outlined-text-field-disabled-input-text-color: var(--light-color);
    --mdc-outlined-text-field-input-text-placeholder-color: var(--light-color);
    --mdc-outlined-text-field-focus-input-text-placeholder-color: var(--light-color);
    --mdc-outlined-text-field-label-text-color: var(--light-color);
    --mdc-outlined-text-field-focus-label-text-color: var(--light-color);
    --mdc-outlined-text-field-hover-label-text-color: var(--light-color);
    --mdc-outlined-text-field-disabled-label-text-color: var(--light-color);
    // Outline colors
    --mdc-outlined-text-field-outline-color: var(--light-color);
    --mdc-outlined-text-field-focus-outline-color: var(--light-color);
    --mdc-outlined-text-field-hover-outline-color: var(--light-color);
    --mdc-outlined-text-field-disabled-outline-color: var(--extra-light-gray-color);
    // Error colors
    --mat-form-field-error-text-color: var(--error-color);
    --mdc-outlined-text-field-error-focus-label-text-color: var(--error-color);
    --mdc-outlined-text-field-error-hover-label-text-color: var(--error-color);
    --mdc-outlined-text-field-error-label-text-color: var(--error-color);
    --mdc-outlined-text-field-error-outline-color: var(--error-color);
    --mdc-outlined-text-field-error-focus-outline-color: var(--error-color);
    --mdc-outlined-text-field-error-hover-outline-color: var(--error-color);

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: calc(var(--mat-form-field-container-height) / 2 - 4px);

      &.mdc-floating-label--float-above{
        top: calc(var(--mat-form-field-container-height) / 2 - 2px);
      }
    }

    .mat-mdc-form-field-bottom-align::before{
      height: auto;
    }
    .mat-mdc-form-field-error-wrapper {
      padding: 0 0.75rem;
    }
  }
}
/* *** Login form end *** */

/* *** Language form *** */
.change-language-form{
  .mat-mdc-form-field{
    width: 100%;
    --mat-form-field-container-height: 1.75rem;
    --mat-form-field-container-text-size: 0.75rem;
    --mdc-outlined-text-field-label-text-size: 0.75rem;
    --mat-form-field-outlined-label-text-populated-size: 0.875rem;
    --mdc-typography-body2-font-size: 0.75rem;
    --mat-form-field-container-text-line-height: 1.75rem;
    --mdc-outlined-text-field-container-color: transparent;
    --mat-select-trigger-text-line-height: 1.75rem;
    --mat-select-trigger-text-size: 0.75rem;
    --mat-form-field-container-vertical-padding: 0px;
    --mat-form-field-filled-with-label-container-padding-top: 0.375rem;
    --mat-form-field-filled-with-label-container-padding-bottom: 0rem;

    --mdc-outlined-text-field-focus-label-text-color: var(--header-text);
    --mdc-outlined-text-field-label-text-color: var(--header-text);
    --mdc-outlined-text-field-hover-label-text-color: var(--header-text);
    --mdc-outlined-text-field-input-text-color: var(--header-text);
    --mat-select-enabled-trigger-text-color: var(--header-text);
    --mat-select-enabled-arrow-color: var(--header-text);
    --mat-select-focused-arrow-color: var(--header-text);
    --mdc-outlined-text-field-active-indicator-color: var(--header-text);
    --mdc-outlined-text-field-focus-active-indicator-color: var(--header-text);
    --mdc-outlined-text-field-hover-active-indicator-color: var(--header-text);
    --mat-select-arrow-transform: translateY(-2px);

    .mat-mdc-text-field-wrapper {
      &.mdc-text-field {
        padding: 0 0.75rem;
      }

      .mat-mdc-select {
        padding-left: 1.75rem;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
  }
}
/* *** Language form end *** */

/* *** Custom form *** */
.custom-form{
  .mat-mdc-form-field{
    width: 100%;
    --mat-form-field-container-height: 1.875rem;
    --mat-form-field-container-vertical-padding: 0;
    --mat-form-field-container-text-line-height: 1.875rem;
    --mat-form-field-container-text-size: 0.75rem;
    --mat-select-trigger-text-size: 0.75rem;
    --mdc-outlined-text-field-label-text-size: 0.75rem;
    --mat-form-field-outlined-label-text-populated-size: 0.75rem;
    --mdc-outlined-text-field-container-shape: 0.375rem;
    --mat-form-field-subscript-text-line-height: 1.125rem;
    --mat-form-field-subscript-text-size: 0.625rem;

    .mat-mdc-text-field-wrapper:has(input[type="number"]) {
      position: relative;
      padding-right: 0;
      padding-left: 0.25rem;

        input{
          text-align: right;
        }

        // Pozicija strelica unutar input[type="number"]
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          margin-left: 4px;
        }
      }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: calc(var(--mat-form-field-container-height) / 2 - 6px);

      &.mdc-floating-label--float-above{
        top: calc(var(--mat-form-field-container-height) / 2 - 2px);
        line-height: 2;
      }
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--focused {
      &:not(.mdc-text-field--disabled){
        outline: 3px solid var(--focus-color);
        outline-offset: 3px;
        border-radius: 0.375rem;
      }
    }

    .mat-mdc-progress-spinner {
      --mdc-circular-progress-active-indicator-color: var(--gray-color);
      position: absolute;
      right: 0;
      top: 4px;
    }

    #display-picker-value {
      position: absolute;
      text-align: left;
      width: 100%;
      font-size: 0.875rem;
    }

    // .mdc-text-field--outlined {
    //   padding-left: max(0.75rem, calc(var(--mdc-outlined-text-field-container-shape) + 4px));
    //   padding-right: max(0.75rem, var(--mdc-outlined-text-field-container-shape));
    // }

    .mat-mdc-form-field-bottom-align::before{
      height: auto;
    }
    .mat-mdc-form-field-error-wrapper {
      padding: 0 0.75rem;

      &:has(.num-error){
        padding: 0 0.5rem;
      }

      .mat-mdc-form-field-error {
        max-width: 100%;
        @include b.text-ellipsis;

        &.white-space-normal{
          white-space: pre-line;
          line-height: 1.5;
        }
      }
    }

    &.search-form-field{
      .mat-mdc-form-field-icon-suffix {
        padding: 0 0.375rem 0 0.25rem;

        .mat-icon{
          width: 1.25rem;
          height: 1.25rem;
          font-size: 1.25rem;
          line-height: 1.25rem;
        }
      }
    }

    &.custom-suffix{
      .mat-mdc-form-field-icon-suffix {
        padding: 0 0.75rem 0 0.375rem;

        span{
          font-size: 0.75rem;
        }
      }

      &.suffix-primary{
        color: var(--primary-color);

        .mat-icon{
          padding: 0;
          @include b.mat-icon(0.875rem, 0.875rem, 0.875rem, 0.875rem);
        }
      }
    }

    &.custom-datepicker{
      .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 1.75rem;
        --mdc-icon-button-icon-size: 1rem;
        padding: 0;
        right: 0.375rem;
      }
    }

    &.num-field{
      .mat-mdc-text-field-wrapper {
        position: relative;
        padding-right: 0;
        padding-left: 0.25rem;

          input{
            text-align: right;
          }

          // Pozicija strelica unutar input[type="number"]
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            margin-left: 4px;
          }
      }
    }
  }

  .hide-subscript{
    .mat-mdc-form-field{
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }

  .small-subscript{
    .mat-mdc-form-field{
      --mat-form-field-subscript-text-line-height: 0.75rem;
      --mat-form-field-subscript-text-size: 0.5rem;
    }
  }

  &.reduced-form{
    .mat-mdc-form-field{
      --mat-form-field-container-height: 1.5rem;
      --mat-form-field-container-vertical-padding: 0;
      --mat-form-field-container-text-line-height: 1.5rem;
      --mat-form-field-container-text-size: 0.75rem;
      --mat-select-trigger-text-size: 0.75rem;
      --mdc-outlined-text-field-label-text-size: 0.625rem;
      --mat-form-field-outlined-label-text-populated-size: 0.625rem;
      --mdc-outlined-text-field-container-shape: 0.375rem;
      --mat-form-field-subscript-text-line-height: 0.625rem;

      .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: calc(var(--mat-form-field-container-height) / 2 - 6px);

        &.mdc-floating-label--float-above{
          top: calc(var(--mat-form-field-container-height) / 2);
          line-height: 2;
        }
      }

      .mdc-text-field--outlined {
        padding-left: 0.375rem;
        padding-right: 0.375rem;

        .mat-mdc-input-element{
          text-align: center;
        }
      }

      &.custom-datepicker .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 1.5rem;
        --mdc-icon-button-icon-size: 0.875rem;
      }
    }
  }

  .num-box{
    .mat-mdc-form-field{
      .mat-mdc-text-field-wrapper {
        position: relative;
        padding-right: 0;
        padding-left: 0.25rem;

          input{
            text-align: right;
          }

          // Pozicija strelica unutar input[type="number"]
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            margin-left: 4px;
          }
      }
    }

    .num-error {
      font-size: 0.625rem;
      line-height: 1.4;
      width: max-content;
    }
  }

  &__actions{
    @include b.flex(row, flex-start, center);
    column-gap: 1.5rem;
  }

  &.header-form{
    .mat-mdc-form-field{
      // Text colors
      --mdc-outlined-text-field-input-text-color: var(--light-color);
      --mdc-outlined-text-field-focus-input-text-color: var(--light-color);
      --mdc-outlined-text-field-disabled-input-text-color: var(--light-color);
      --mdc-outlined-text-field-input-text-placeholder-color: var(--light-color);
      --mdc-outlined-text-field-focus-input-text-placeholder-color: var(--light-color);
      --mdc-outlined-text-field-label-text-color: var(--light-color);
      --mdc-outlined-text-field-focus-label-text-color: var(--light-color);
      --mdc-outlined-text-field-hover-label-text-color: var(--light-color);
      --mdc-outlined-text-field-disabled-label-text-color: var(--light-color);

      --mat-select-enabled-trigger-text-color: var(--light-color);
      --mat-select-disabled-trigger-text-color: var(--light-gray-color);
      --mat-select-placeholder-text-color: var(--light-color);
      --mat-select-enabled-arrow-color: var(--light-color);
      --mat-select-disabled-arrow-color: var(--light-gray-color);
      --mat-select-focused-arrow-color: var(--light-color);
      --mat-select-invalid-arrow-color: var(--error-color);

      // Outline colors
      --mdc-outlined-text-field-outline-color: var(--light-color);
      --mdc-outlined-text-field-focus-outline-color: var(--light-color);
      --mdc-outlined-text-field-hover-outline-color: var(--light-color);
      --mdc-outlined-text-field-disabled-outline-color: var(--extra-light-gray-color);
      // Error colors
      --mat-form-field-error-text-color: var(--error-color);
      --mdc-outlined-text-field-error-focus-label-text-color: var(--error-color);
      --mdc-outlined-text-field-error-hover-label-text-color: var(--error-color);
      --mdc-outlined-text-field-error-label-text-color: var(--error-color);
      --mdc-outlined-text-field-error-outline-color: var(--error-color);
      --mdc-outlined-text-field-error-focus-outline-color: var(--error-color);
      --mdc-outlined-text-field-error-hover-outline-color: var(--error-color);

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }

  &.page-form{
    .mat-mdc-form-field{
      --mat-form-field-subscript-text-line-height: 0.75rem;
      --mat-form-field-subscript-text-size: 0.5rem;
    }
  }
}
/* *** Custom form end *** */


//Special panel styles
.mat-mdc-select-panel{

  &.custom-mat-select-panel-class{
    padding: 0;

    .mat-mdc-option {
      font-size: 12px;
      line-height: 1.35;
      min-height: 2.5em;
      height: auto;
      padding: 0 0.75rem 0 0.875rem;

      &.transaction-indented{
        padding-left: 2rem;
      }

      .mat-pseudo-checkbox{
        width: 1rem;
        height: 1rem;
        margin-right: 0;
        margin-left: 0.75rem;
      }

      .mat-pseudo-checkbox.mat-pseudo-checkbox-checked{
        background: transparent;
      }

      .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked{
        &::after {
          width: 10px;
          height: 4px;
        }
      }

      .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
        width: 9px;
        height: 3px;
      }

      .mdc-list-item__primary-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 0.5rem;
      }

      .fake-circle {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: .375rem;
        position: relative;
        top: 4px;
      }

      &.green{
        .fake-circle {
          background-color: var(--primary-color);
        }
      }

      &.blue{
        .fake-circle {
          background-color: var(--alert-info);
        }
      }

      &.dark{
        .fake-circle {
          background-color: var(--dark-color);
        }
      }

      &.deleted-option {
        background-color: var(--disabled-element-bg);
        color: var(--disabled-element-text);
        // .left-option {
        //   color: #8f8f8f;
        // }
      }
    }

    .mat-mdc-optgroup{
      --mat-optgroup-label-text-line-height: 1.5rem;
      --mat-optgroup-label-text-size: 0.8125rem;
      --mat-optgroup-label-text-tracking: 0.025em;
      --mat-optgroup-label-text-weight: 500;

      .mat-mdc-optgroup-label{
        min-height: 1.875rem;
        font-size: 0.75rem;
        line-height: 1.5;
        text-transform: uppercase;
      }
    }
  }

  &.custom-mat-multiselect-panel-class{
    padding: 0;

    .mat-mdc-option {
      font-size: 12px;
      line-height: 1.35;
      min-height: 2.5em;
      height: auto;
      padding: 0 0.75rem 0 0.875rem;

      &.transaction-indented{
        padding-left: 2rem;
      }

      .mat-pseudo-checkbox{
        width: 0.9375rem;
        height: 0.9375rem;
        margin-right: 0.5rem;
        margin-left: 0;
      }

      .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked{
        &::after {
          width: 10px;
          height: 4px;
        }
      }

      .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
        width: 9px;
        height: 3px;
      }

      &.deleted-option {
        background-color: var(--disabled-element-bg);
        color: var(--disabled-element-text);
        // .left-option {
        //   color: #8f8f8f;
        // }
      }
    }

    .mat-optgroup-label{
      font-size: 12px;
      line-height: 1.5;
    }
  }

  &.long-mat-multiselect-panel-class{
    padding: 0;
    max-height: 325px;

    .mat-mdc-option {
      font-size: 12px;
      line-height: 1.35;
      min-height: 2em;
      height: auto;
      padding: 0 0.75rem 0 0.875rem;

      &.transaction-indented{
        padding-left: 2rem;
      }

      .mat-pseudo-checkbox{
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 0.5rem;
        margin-left: 0;
      }

      .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked{
        &::after {
          width: 10px;
          height: 4px;
        }
      }

      .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
        width: 9px;
        height: 3px;
      }

      &.deleted-option {
        background-color: var(--disabled-element-bg);
        color: var(--disabled-element-text);
        // .left-option {
        //   color: #8f8f8f;
        // }
      }
    }

    .mat-optgroup-label{
      font-size: 12px;
      line-height: 1.35;
    }
  }

  &.common-tree-dropdown-panel{
    padding: 0;

    .mat-mdc-option {
      font-size: 12px;
      line-height: 1.35;
      min-height: 2em;
      height: auto;
      padding: 0 0.75rem 0 0.875rem;
      width: 100%;

      &.transaction-indented{
        padding-left: 2rem;
      }

      .mat-pseudo-checkbox{
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 0.5rem;
        margin-left: 0;
      }

      .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked{
        &::after {
          width: 10px;
          height: 4px;
        }
      }

      .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
        width: 9px;
        height: 3px;
      }

      &.deleted-option {
        background-color: var(--disabled-element-bg);
        color: var(--disabled-element-text);
        // .left-option {
        //   color: #8f8f8f;
        // }
      }
    }

    .mat-optgroup-label{
      font-size: 12px;
      line-height: 1.5;
    }
  }

  &.reduced-mat-select-panel-class{
    padding: 0;

    .mat-mdc-option {
      font-size: 12px;
      line-height: 1.35;
      min-height: 1.25rem;
      height: auto;
      padding: 0 0.75rem 0 0.875rem;

      &.transaction-indented{
        padding-left: 2rem;
      }

      .mat-pseudo-checkbox{
        width: 1rem;
        height: 1rem;
        margin-right: 0;
        margin-left: 0.75rem;
      }

      .mat-pseudo-checkbox.mat-pseudo-checkbox-checked{
        background: transparent;
      }

      .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked{
        &::after {
          width: 10px;
          height: 4px;
        }
      }

      .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
        width: 9px;
        height: 3px;
      }

      .fake-circle {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: .375rem;
        position: relative;
        top: 4px;
      }

      &.green{
        .fake-circle {
          background-color: var(--primary-color);
        }
      }

      &.blue{
        .fake-circle {
          background-color: var(--alert-info);
        }
      }

      &.dark{
        .fake-circle {
          background-color: var(--dark-color);
        }
      }

      &.deleted-option {
        background-color: var(--disabled-element-bg);
        color: var(--disabled-element-text);
        // .left-option {
        //   color: #8f8f8f;
        // }
      }
    }

    .mat-mdc-optgroup{
      --mat-optgroup-label-text-line-height: 1.25rem;
      --mat-optgroup-label-text-size: 0.8125rem;
      --mat-optgroup-label-text-tracking: 0.025em;
      --mat-optgroup-label-text-weight: 500;

      .mat-mdc-optgroup-label{
        min-height: 1.25rem;
        font-size: 0.75rem;
        line-height: 1.25;
        text-transform: uppercase;
      }

      .mdc-list-item__primary-text{
        font-size: 0.6875rem;
      }
    }
  }

  &.language-select-panel-class {
    padding: 0;
    min-width: 100%!important;
    position: relative;

    .mat-mdc-option {
      font-size: 12px;
      line-height: 1.35;
      min-height: 2.5em;
      height: auto;
      padding: 0 0.375rem;

      &.transaction-indented{
        padding-left: 2rem;
      }

      .mat-pseudo-checkbox.mat-pseudo-checkbox-checked{
        background: transparent;
      }

      .mat-pseudo-checkbox-minimal {
        margin-left: 0;
        &.mat-pseudo-checkbox-checked{
          &::after {
            width: 10px;
            height: 4px;
          }
        }
      }
    }

    .mat-optgroup-label{
      font-size: 12px;
      line-height: 1.5;
    }
  }

}

.mat-table-select-panel{
  .mat-mdc-select-panel{
      padding: 0;

      .mat-mdc-option {
        font-size: 12px;
        line-height: 1.35;
        min-height: 2.5em;
        height: auto;
        padding: 0 0.625rem;

        .mat-pseudo-checkbox{
          width: 1rem;
          height: 1rem;
          margin-right: 0;
          margin-left: 0.75rem;
        }

        .mat-pseudo-checkbox.mat-pseudo-checkbox-checked{
          background: transparent;
        }

        .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked{
          &::after {
            width: 10px;
            height: 4px;
          }
        }

        .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
          width: 9px;
          height: 3px;
        }

        &.deleted-option {
          background-color: var(--disabled-element-bg);
          color: var(--disabled-element-text);
          // .left-option {
          //   color: #8f8f8f;
          // }
        }
      }

      .mat-optgroup-label{
        font-size: 12px;
        line-height: 1.5;
      }
  }
}

.custom-autocomplete{
  &.mat-mdc-autocomplete-panel{
    .mat-mdc-option {
      font-size: 12px;
      line-height: 1.35;
      min-height: 2em;
      height: auto;
      padding: 0 0.75rem 0 0.875rem;

      &.transaction-indented{
        padding-left: 2rem;
      }

      .mat-pseudo-checkbox{
        width: 1rem;
        height: 1rem;
        margin-right: 0;
        margin-left: 0.75rem;
      }

      .mat-pseudo-checkbox.mat-pseudo-checkbox-checked{
        background: transparent;
      }

      .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked{
        &::after {
          width: 10px;
          height: 4px;
        }
      }

      .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
        width: 9px;
        height: 3px;
      }

      &.deleted-option {
        background-color: var(--disabled-element-bg);
        color: var(--disabled-element-text);
        // .left-option {
        //   color: #8f8f8f;
        // }
      }
    }
  }
}

.large-autocomplete{
  &.mat-mdc-autocomplete-panel{
    .mat-mdc-option {
      font-size: 0.875rem;
      line-height: 1.35;
      min-height: 2em;
      height: auto;
      padding: 0 0.75rem 0 0.875rem;


      .mat-pseudo-checkbox{
        width: 1rem;
        height: 1rem;
        margin-right: 0;
        margin-left: 0.75rem;
      }

      .mat-pseudo-checkbox.mat-pseudo-checkbox-checked{
        background: transparent;
      }

      .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked{
        &::after {
          width: 10px;
          height: 4px;
        }
      }

      .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
        width: 9px;
        height: 3px;
      }

      &.deleted-option {
        background-color: var(--disabled-element-bg);
        color: var(--disabled-element-text);
        // .left-option {
        //   color: #8f8f8f;
        // }
      }
    }
  }
}


// Custom datepicker panel
  .mat-datepicker-content .mat-calendar.filter-datepicker {
    max-width: 200px;
    height: auto;
  }

  .mat-datepicker-content .mat-calendar.filter-datepicker .mat-calendar-body{
    font-size: 12px;
  }

  .mat-datepicker-content
    .mat-calendar.filter-datepicker
    .mat-calendar-body-cell.mat-calendar-body-active {
      color: var(--light-color-1);

    .mat-calendar-body-today.mat-calendar-body-selected,
    .mat-calendar-body-selected {
      box-shadow: none;
      color: var(--light-color-1);
    }
  }

  .mat-calendar.filter-datepicker .mat-calendar-controls {
    margin: 0 calc(4.7142857143% - 16px) 5%;

    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 38px
    }

    .mat-calendar-period-button {
      font-size: 12px;
      margin-right: 0;

      .mdc-button__label {
        display: flex;
        align-items: center;
      }
    }
  }









