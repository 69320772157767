@use "/src/assets/scss/base/" as b;

.custom-mat-table {
  margin-top: 0;

  // SCROLLABLE TABLE
  .mat-table-scrollable {
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
    background: white;

    .mat-mdc-table {
      .mat-mdc-header-row {
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }
  }

  .mat-mdc-table{
    --mdc-checkbox-state-layer-size: 1.75rem;

    .mat-mdc-header-row{
      --mat-table-header-container-height: 1.75rem;
      --mat-table-header-headline-size: 0.75rem;
      --mat-table-header-headline-color: var(--table-header-text);
      --mat-sort-arrow-color: var(--table-header-text);
      background-color: var(--table-header);
      min-height: var(--mat-table-header-container-height);
      z-index: 10!important;


      .mat-sort-header-content {
        height: var(--mat-table-header-container-height);
      }

      .mat-mdc-header-cell{
        line-height: 1.3;
        .mdc-checkbox{
          --mdc-checkbox-unselected-icon-color: var(--table-header-text);
          --mdc-checkbox-unselected-focus-icon-color: var(--table-header-text);
          --mdc-checkbox-unselected-hover-icon-color: var(--table-header-text);

          .mdc-checkbox__background {
            width: 0.9375rem;
            height: 0.9375rem;
            top: calc((var(--mdc-checkbox-state-layer-size) - 0.9375rem) / 2);
            left: calc((var(--mdc-checkbox-state-layer-size) - 0.9375rem) / 2);
          }
        }

        &.mat-column-select,
        &.mat-column-Select,
        &.mat-column-operations,
        &.mat-column-Operations,
        &.mat-column-actions,
        &.mat-column-Actions{
          .mat-sort-header-arrow{
            display: none;
          }
        }
      }
    }

    .mat-mdc-footer-row{
      --mat-table-footer-container-height: 2rem;
      --mat-table-footer-supporting-text-size: 0.75rem;
      --mat-table-footer-supporting-text-weight: 500
    }

      tr.mat-mdc-header-row,
      tr.mat-mdc-row {
        height: 1.75rem;
      }

      .mat-mdc-row{
        --mat-table-row-item-container-height: 1.75rem;
        --mat-table-row-item-label-text-color: var(--page-text);
        --mat-table-row-item-label-text-size: 0.75rem;
        --mat-table-row-item-label-text-weight: 400;
        min-height: var(--mat-table-row-item-container-height);

        &.optimized{
          background-color: var(--alert-danger);
          color: var(--light-color-2);

          .mat-mdc-cell{
            .mdc-checkbox{
              --mdc-checkbox-unselected-icon-color: var(--light-color-2);
              --mdc-checkbox-unselected-focus-icon-color: var(--light-color-2);
              --mdc-checkbox-unselected-hover-icon-color: var(--light-color-2);
            }

            .custom-indeterminate-check-box {
              width: 18px;
              height: 18px;
              font-size: 18px;
              line-height: 18px;
              color: var(--gray-color);
            }

            .mat-mdc-form-field{
              // Text colors
              --mdc-outlined-text-field-input-text-color: var(--light-color-2);
              --mdc-outlined-text-field-disabled-input-text-color: var(--page-borders);
              --mdc-outlined-text-field-input-text-placeholder-color: var(--light-color-2);
              --mdc-outlined-text-field-label-text-color: var(--light-color-2);
              --mdc-outlined-text-field-hover-label-text-color: var(--light-color-2);
              --mdc-outlined-text-field-focus-label-text-color: var(--light-color-2);
              --mdc-outlined-text-field-disabled-label-text-color: var(--page-borders);
              --mat-select-enabled-trigger-text-color: var(--light-color-2);
              --mat-select-enabled-arrow-color: var(--light-color-2);
              --mat-select-focused-arrow-color: var(--light-color-2);
              // Outline colors
              --mdc-outlined-text-field-outline-color: var(--light-color-2);
              --mdc-outlined-text-field-hover-outline-color: var(--light-color-2);
              --mdc-outlined-text-field-focus-outline-color: var(--light-color-2);
              --mdc-outlined-text-field-disabled-outline-color: var(--page-borders);
            }
          }
        }
      }

      .mat-mdc-footer-row {
        min-height: 1.75rem;
        background-color: var(--table-footer);

        .mat-mdc-footer-cell {
          color: var(--table-footer-text);
        }
      }

      .mat-mdc-header-cell,
      .mat-mdc-cell{
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      .mat-mdc-header-cell.text-left,
      .mat-mdc-cell.text-left,
      .mat-mdc-footer-cell.text-left{
        justify-content: flex-start;
        text-align: left;
        span{
          text-align: left;
        }
      }

      .mat-mdc-header-cell.text-right,
      .mat-mdc-cell.text-right,
      .mat-mdc-footer-cell.text-right{
        justify-content: flex-end;
        text-align: right;
        span{
          text-align: right;
        }

        .mat-sort-header-container {
          flex-direction: row-reverse;
          column-gap: 0.25rem;
        }
      }

      .mat-mdc-header-cell:first-of-type,
      .mat-mdc-cell:first-of-type,
      .mat-mdc-footer-cell:first-of-type{
          padding-left: 0.25rem;
      }

      .mat-mdc-header-cell:last-of-type,
      .mat-mdc-cell:last-of-type,
      .mat-mdc-footer-cell:last-of-type{
          padding-right: 0.25rem;
      }

      .mat-mdc-header-cell,
      .mat-mdc-cell,
      .mat-mdc-footer-cell{
          padding: 0 0.25rem;
      }

    .mat-mdc-cell {
      .mat-mdc-form-field{
        width: 100%;
        --mat-form-field-container-height: 1.5rem;
        --mat-form-field-container-vertical-padding: 0;
        --mat-form-field-container-text-line-height: 1.5rem;
        --mat-form-field-container-text-size: 0.75rem;
        --mat-select-trigger-text-size: 0.75rem;
        --mdc-outlined-text-field-label-text-size: 0.75rem;
        --mat-form-field-outlined-label-text-populated-size: 0.75rem;
        --mdc-outlined-text-field-container-shape: 0.375rem;

        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
          top: calc(var(--mat-form-field-container-height) / 2 - 2px);

          &.mdc-floating-label--float-above{
            top: calc(var(--mat-form-field-container-height) / 2 - 1px);
            line-height: 2;
          }
        }

        .mat-mdc-form-field-subscript-wrapper{
          display: none;
        }

        &.custom-datepicker{
          .mat-mdc-icon-button.mat-mdc-button-base {
            --mdc-icon-button-state-layer-size: 1.875rem;
            --mdc-icon-button-icon-size: 1.125rem;
            padding: 0;
          }
        }
      }

      .mdc-checkbox{
        .mdc-checkbox__background {
          width: 0.9375rem;
          height: 0.9375rem;
          // top: calc((var(--mdc-checkbox-state-layer-size) - 0.9375rem) / 2);
          // left: calc((var(--mdc-checkbox-state-layer-size) - 0.9375rem) / 2);
        }
      }
    }

    .mat-mdc-header-cell.mat-column-actions,
    .mat-mdc-cell.mat-column-actions{
      justify-content: center;

      button{
        &.mat-mdc-unelevated-button{
          --mdc-filled-button-label-text-size: 0.75rem;
          --mdc-filled-button-label-text-weight: 400;
          --mdc-filled-button-container-height: 1.5rem;
          --mat-filled-button-horizontal-padding: 0.5rem;
          --mdc-filled-button-label-text-tracking: 0.05em;
          min-width: auto;
          line-height: 1.5rem;

          .mdc-button__label {
            @include b.flex(row, center, center);
            column-gap: 0.25rem;

            .mat-icon{
              @include b.mat-icon(0.8125rem, 0.8125rem, 0.8125rem, 0.8125rem);
            }
          }
        }
      }

      .custom-action-btn {
        background-color: var(--light-gray-color);
        color: var(--gray-color);
        text-decoration: none;
        padding: .2rem .5rem;
        border-radius: .2rem;
      }
    }

    .mat-mdc-header-cell.mat-column-operations,
    .mat-mdc-cell.mat-column-operations{
      flex: 0 0 7.5rem;
      .mat-mdc-form-field{
        max-width: 7rem;
      }
    }

    .mat-mdc-cell .custom-check-box,
    .mat-mdc-cell .custom-indeterminate-check-box{
      width: 21px;
      height: 21px;
      font-size: 21px;
      line-height: 21px;
    }
    .mat-mdc-cell .custom-check-box{
      color: var(--primary-color);
    }
    .mat-mdc-cell .custom-indeterminate-check-box{
      color: var(--gray-color);
    }
  }

  .mat-mdc-table {

    .mat-mdc-cell.mat-column-actions {
      .open-purchase-order-link {
        font-size: 12px;
        line-height: 1.25;
        background: inherit;
        color: b.$primary-color;
        border: 1px solid b.$primary-color;
      }
    }

    .mat-mdc-cell.mat-column-Naziv {
      span {
        display: block;
        text-align: left;
      }
    }

    .mat-mdc-cell.mat-column-Stanje.yellow-cell {
      background-color: yellow;
    }

    .mat-mdc-header-cell,
    .mat-footer-cell,
    .mat-mdc-cell {
      font-size: 12px;
      justify-content: center;
      text-align: center;
    }

    .mat-mdc-header-cell.text-left,
    .mat-footer-cell.text-left,
    .mat-mdc-cell.text-left {
      justify-content: flex-start;
      text-align: left;
      span{
        text-align: left;
      }
    }

    .mat-mdc-header-cell.text-right,
    .mat-footer-cell.text-right,
    .mat-mdc-cell.text-right {
      justify-content: flex-end;
      text-align: right;
      span{
        text-align: right;
      }
    }

    .mat-footer-cell{
      font-weight: 500;
    }

    .mat-mdc-header-cell:first-of-type,
    .mat-mdc-cell:first-of-type,
    .mat-mdc-footer-cell:first-of-type {
      padding-left: 0.5rem;
    }
    .mat-mdc-header-cell:last-of-type,
    .mat-mdc-cell:last-of-type,
    .mat-mdc-footer-cell:last-of-type {
      padding-right: 0.5rem;
    }
    .mat-mdc-header-cell.mat-column-id,
    .mat-mdc-cell.mat-column-id,
    .mat-mdc-header-cell.mat-column-select,
    .mat-mdc-cell.mat-column-select {
      max-width: 75px;
    }

    .mat-mdc-header-cell.mat-column-select,
    .mat-mdc-cell.mat-column-select,
    .mat-mdc-header-cell.mat-column-Select,
    .mat-mdc-cell.mat-column-Select {
      .mat-mdc-checkbox{
          .mat-internal-form-field>label {
            padding-left: 0;
          }
      }
    }

    .mat-mdc-header-cell.mat-column-select .mat-checkbox-inner-container,
    .mat-mdc-cell.mat-column-select .mat-checkbox-inner-container {
      width: 13px;
      height: 13px;
    }

    .mat-mdc-cell .custom-check-box,
    .mat-mdc-cell .custom-indeterminate-check-box {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 1.0625rem;
      height: 1.0625rem;
      font-size: 1.0625rem;
      line-height: 1.0625rem;
    }
    .mat-mdc-cell .custom-check-box {
      color: b.$primary-color;
    }
    .mat-mdc-cell .custom-indeterminate-check-box {
      color: b.$gray-color;
    }

    .mat-mdc-cell {
      .clickable-link {
        cursor: pointer;
        display: inline-block;
        padding: 2px 6px;
        border-radius: 4px;
        line-height: 15px;
      }
    }
  }

  // Paginations
  .actionsContainer {
    &.lower-container {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 1.5rem;
      row-gap: 0.75rem;

      .actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.25rem;
        order: 2;
      }

      .paginations {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
        width: 100%;
        order: 1;

        .mat-mdc-form-field{
          width: 100%;
          max-width: 4.375rem;

          &+.mat-mdc-form-field{
            margin-left: 0.75rem;
          }

          .mdc-text-field--outlined {
            padding-left: max(0.625rem, var(--mdc-outlined-text-field-container-shape));
            padding-right: max(0.625rem, var(--mdc-outlined-text-field-container-shape));
          }

          .mdc-notched-outline .mdc-floating-label {
            max-width: calc(100% - 0.5rem);
          }
          .mdc-notched-outline .mdc-floating-label--float-above {
            max-width: calc(100% + 0.75rem);
          }

          &.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline .mdc-notched-outline__notch {
            max-width: calc(100% - 1.25rem);
          }
        }

        .mat-mdc-icon-button.mat-mdc-button-base {
          --mdc-icon-button-state-layer-size: 1.75rem;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .mat-paginator-page-size-select {
      margin: -8px 4px 0 4px;
    }
  }

  .mat-mdc-paginator {
    //background: b.$page-bg;
    background: inherit;

    .mat-mdc-paginator-container {
      padding: 0;
      --mat-paginator-container-size: 2rem;

      .mat-mdc-paginator-page-size {
        margin-right: 0px;
      }

      .mat-mdc-paginator-range-label {
        margin: 0 0.5rem;
      }
    }
  }

  .alert,.front-alert {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: b.$page-text;
  }

  &.backend-table {
    .mat-mdc-table .mat-mdc-cell.mat-column-Naziv {
      justify-content: flex-start;
    }

    .backend-table-wrapper{
      padding: 0 2px;

      &.scrollable-table{
        overflow: auto;
      }

      .local-spinner-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .local-spinner {
          width: 9.5rem;
          height: 9.5rem;
          @include b.flex(row, center, center);
          z-index: 100;
          margin: 0;
          border-radius: 50%;
          border: 4px solid transparent;
          border-top-color: var(--primary-color);
          -webkit-animation: spin 2s linear infinite;
          animation: spin 3s linear infinite;

          &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 4px solid transparent;
            border-top-color: var(--primary-color-light);
            -webkit-animation: spin 3s linear infinite;
            animation: spin 4.5s linear infinite;
          }

          &::after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 4px solid transparent;
            border-top-color: var(--primary-color);
            -webkit-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
          }
        }
      }

      @keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }

    .filters-wrapper{
      margin-bottom: 0.5rem;

      .mat-mini-fab {
        width: 28px;
        height: 28px;
        line-height: 28px;
        color: b.$page-bg;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
          0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        display: grid;
        place-content: center;

        .mat-icon {
          width: 15px;
          height: 15px;
          font-size: 15px;
        }
      }
    }
  }

  &.drag-drop-table {
    table {
      width: 100%;
    }

    .mat-form-field {
      max-width: 175px;
    }

    button {
      min-width: 60px;
      line-height: 24px;
      padding: 0 10px;
      font-size: 11px;
      margin: 0;

      &:disabled {
        background: var(--disabled-element-bg);
        border: 1px solid var(--disabled-element-borders);
        color: var(--disabled-element-text);
      }
    }
  }

  .mat-mdc-table .mat-mdc-cell.mat-column-imageView {
    button {
      min-width: 60px;
      line-height: 26px;
      padding: 0 10px;
      font-size: 11px;
      margin: 2px 0px;
    }
  }
}






