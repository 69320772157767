@use "/src/assets/scss/base/" as b;


// LEFT SIDEBAR ACCORDION
.mat-accordion.sidebar-accordion{
  .mat-expansion-panel-spacing {
    margin: 0px;
  }

  .mat-expansion-panel:first-of-type {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .mat-expansion-panel.mat-expanded {
    border-radius: 0px;
  }

  .mat-expansion-panel-header{
    padding: 0 1rem;
  }

  .mat-expansion-panel .mat-expansion-panel-header-title {
    margin-right: 0px;
    color: white;
  }

  .mat-expansion-panel {
    border-bottom: 1px solid var(--sidebar-borders);
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header,
  .mat-expansion-panel .mat-expansion-panel-header:not([aria-disabled=true]):hover,
  .mat-expansion-panel .mat-expansion-panel-body {
    background-color: var(--sidebar-bg);
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover,
  .mat-expansion-panel.mat-expanded .mat-expansion-panel-header:hover {
    background-color: var(--sidebar-hover);
  }

  .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
    background-color: var(--sidebar-hover);
  }

  .mat-expansion-panel .mat-expansion-panel-header:hover .mat-expansion-panel-header-title,
  .mat-expansion-panel .mat-expansion-panel-header:hover .mat-expansion-panel-header-title .panelHomeTitle{
    color: var(--sidebar-active);
  }

  .mat-expansion-panel .mat-expansion-panel-body {
    padding: 0px;
  }
}
