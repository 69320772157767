// TEXT ELLIPSIS
@mixin text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// FLEX
@mixin flex($direction, $justify, $align ) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

// MAT-ICON STYLE
@mixin mat-icon($w, $h, $fs, $lh) {
  width: $w;
  height: $h;
  font-size: $fs;
  line-height: $lh;
}

// CUSTOM BUTTONS
@mixin custom-btn{
  font-weight: normal;
  outline: none;
}

@mixin custom-btn-medium($mw, $lh, $fs){
  @include custom-btn;
  min-width: $mw;
  line-height:  $lh;
  font-size: $fs;
}


//  RESPONSIVE
@mixin lg-screen {
    @media screen and (max-width: 1199.8px) {
      @content;
    }
}
@mixin laptop {
    @media screen and (max-width: 991.8px) {
      @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 767.8px) {
      @content;
    }
}
@mixin mobile {
    @media screen and (max-width: 575.8px) {
      @content;
    }
}
@mixin sm-mobile {
    @media screen and (max-width: 450px) {
      @content;
    }
}
