@use "/src/assets/scss/base/" as b;

.custom-form,
.custom-mat-table .mat-mdc-table .mat-mdc-cell{
  .mat-mdc-checkbox{
    --mdc-checkbox-selected-checkmark-color: white;
    --mat-checkbox-label-text-line-height: 1.125rem;
    --mat-checkbox-label-text-size: 0.75rem;
    --mdc-checkbox-state-layer-size: 2rem;

    .mdc-checkbox {
      flex: 0 0 15px;
      width: 15px;
      height: 15px;

      .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
        border-color: var(--mdc-checkbox-selected-checkmark-color);
      }
      .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
        color: var(--mdc-checkbox-selected-checkmark-color);
      }
    }

    .mdc-checkbox__background {
      width: 15px;
      height: 15px;
    }

    .mat-mdc-checkbox-touch-target{
      width: 2rem;
      height: 2rem;
    }
  }
}
