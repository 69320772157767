.custom-slide-toggle{
  &.mat-mdc-slide-toggle{
    --mdc-switch-state-layer-size: 1.75rem;
    --mdc-switch-selected-icon-size: 0.875rem;
    --mdc-switch-unselected-icon-size: 0.875rem;
    --mdc-switch-track-height: 0.75rem;
    --mat-switch-unselected-handle-size: 1.125rem;
    --mat-switch-selected-handle-size: 1.125rem;
    --mat-switch-pressed-handle-size: 1.125rem;
    --mat-switch-with-icon-handle-size: 1.125rem;
    --mdc-switch-handle-height: 1.125rem;
    --mdc-switch-handle-width: 1.125rem;
  }
}
