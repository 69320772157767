@use "/src/assets/scss/base/" as b;

.custom-form{
  .mat-mdc-radio-button{
    --mat-radio-label-text-size: 0.8125rem;

    .mdc-radio {
      --mdc-radio-state-layer-size: 2rem;
      padding: calc((var(--mdc-radio-state-layer-size) - 1.125rem) / 2);
      width: 1.125rem;
      height: 1.125rem;

      .mdc-radio__background::before {
        top: calc(-1*(var(--mdc-radio-state-layer-size) - 1.125rem) / 2);
        left: calc(-1*(var(--mdc-radio-state-layer-size) - 1.125rem) / 2);
        width: var(--mdc-radio-state-layer-size);
        height: var(--mdc-radio-state-layer-size);
      }
    }


    .mdc-radio__background {
      width: 1.125rem;
      height: 1.125rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .mdc-radio__inner-circle {
        position: relative;
        border-width: 0.625rem;
      }
    }

    .mat-mdc-radio-touch-target{
      width: 2rem;
      height: 2rem;
    }
  }

  .map-filters{
    .mat-mdc-radio-button{

      .mdc-radio {
        display: none;
      }
    }
  }
}
