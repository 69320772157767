@use "/src/assets/scss/base/" as b;


// Unelevated - Flat Buttons
.login-form{
  .mat-mdc-unelevated-button{
    --mdc-filled-button-label-text-size: 0.875rem;
    --mdc-filled-button-label-text-tracking: 0.025em;
    --mdc-filled-button-label-text-weight: 500;
    --mdc-filled-button-container-height: 2.5rem;
    --mdc-filled-button-container-shape: 0.375rem;
    --mat-filled-button-horizontal-padding: 0.75rem;
    --mdc-filled-button-disabled-container-color: var(--disabled-element-bg);
    --mdc-filled-button-disabled-label-text-color: var(--disabled-element-text);
    width: 100%;
    text-transform: uppercase;
  }
}

.custom-flat-btn{
  &.mat-mdc-unelevated-button{
    --mdc-filled-button-label-text-size: 0.8125rem;
    --mdc-filled-button-label-text-tracking: 0.025em;
    --mdc-filled-button-label-text-weight: 400;
    --mdc-filled-button-container-height: 1.75rem;
    --mdc-filled-button-container-shape: 0.25rem;
    --mat-filled-button-horizontal-padding: 0.75rem;
    --mat-filled-button-icon-spacing: 0.25rem;
    --mat-filled-button-icon-offset: -2px;
    line-height: 1.75rem;
    min-width: auto;

    &:disabled{
      background-color: var(--disabled-element-bg);
      color: var(--disabled-element-text);
    }

    .mat-mdc-button-touch-target {
      height: 2rem;
    }

    .mdc-button__label{
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 0.375rem;

      .material-icons-outlined{
        @include b.mat-icon(1rem, 1rem, 1rem, 1rem);
      }
    }

    .mat-icon{
      @include b.mat-icon(0.875rem, 0.875rem, 0.875rem, 0.9375rem);
    }
  }

  &.table-flat-btn{
    --mdc-filled-button-label-text-size: 0.75rem;
    --mdc-filled-button-container-height: 1.5rem;
    --mat-filled-button-horizontal-padding: 0.5rem;
    line-height: 1.5rem;
    min-width: auto;

    .mat-mdc-button-touch-target {
      height: 2rem;
    }
  }
}

// Outlined - Stroked Buttons
.custom-stroked-btn{
  &.mat-mdc-outlined-button{
    --mdc-outlined-button-label-text-size: 0.8125rem;
    --mdc-outlined-button-label-text-tracking: 0.025em;
    --mdc-outlined-button-label-text-weight: 400;

    --mdc-outlined-button-container-height: 1.75rem;
    --mdc-outlined-button-container-shape: 0.25rem;
    --mat-outlined-button-horizontal-padding: 0.625rem;
    --mat-outlined-button-icon-spacing: 0.375rem;
    --mat-outlined-button-icon-offset: 0;
    line-height: 1.75rem;
    min-width: auto;

    .mat-mdc-button-touch-target {
      height: 1.75rem;
    }

    .mat-icon{
      @include b.mat-icon(0.9375rem, 0.9375rem, 0.9375rem, 0.9375rem);
    }

    &:disabled{
      background-color: var(--disabled-element-bg);
      color: var(--disabled-element-text);
      border: 1px solid var(--disabled-element-text);
    }

    &.show-hide-columns{
      .mat-icon{
        @include b.mat-icon(1rem, 1rem, 1rem, 1rem);
      }
    }
  }
}
.section-header,
.section-header .header-actions{
  .mat-mdc-outlined-button{
    --mdc-outlined-button-label-text-size: 0.8125rem;
    --mdc-outlined-button-label-text-tracking: 0.025em;
    --mdc-outlined-button-label-text-weight: 400;

    --mdc-outlined-button-container-height: 1.75rem;
    --mdc-outlined-button-container-shape: 0.25rem;
    --mat-outlined-button-horizontal-padding: 0.625rem;
    --mat-outlined-button-icon-spacing: 0.375rem;
    line-height: 1.75rem;
    min-width: auto;

    .mat-mdc-button-touch-target {
      height: 2rem;
    }

    .mat-icon{
      @include b.mat-icon(0.9375rem, 0.9375rem, 0.9375rem, 0.9375rem);
    }
  }
}

.reset-filter{
  .mat-mdc-outlined-button{
    --mdc-outlined-button-label-text-size: 0.75rem;
    --mdc-outlined-button-label-text-tracking: 0.025em;
    --mdc-outlined-button-label-text-weight: 400;
    --mdc-outlined-button-container-height: 1.75rem;
    --mdc-outlined-button-container-shape: 0.25rem;
    --mat-outlined-button-horizontal-padding: 0.625rem;
    --mdc-outlined-button-label-text-color: var(--page-text);
    line-height: 1.75rem;
    min-width: auto;

    .mat-mdc-button-touch-target {
      height: 2rem;
    }
  }
}

.report-dialog-buttons{
  .mat-mdc-outlined-button{
    --mdc-outlined-button-label-text-size: 0.75rem;
    --mdc-outlined-button-label-text-tracking: 0.025em;
    --mdc-outlined-button-label-text-weight: 400;
    --mdc-outlined-button-container-height: 1.75rem;
    --mdc-outlined-button-container-shape: 0.25rem;
    --mat-outlined-button-horizontal-padding: 0.625rem;
    --mdc-outlined-button-label-text-color: var(--header-text);
    --mdc-outlined-button-outline-color: var(--header-text);
    line-height: 1.75rem;
    min-width: auto;

    .mat-mdc-button-touch-target {
      height: 2rem;
    }

    .mat-icon{
      @include b.mat-icon(0.9375rem, 0.9375rem, 0.9375rem, 0.9375rem);
    }
  }

  &.report-initial-buttons{
    margin-top: 0.5rem;

    .mat-mdc-outlined-button{
      --mdc-outlined-button-label-text-color: var(--primary-color);
      --mdc-outlined-button-outline-color: var(--primary-color);
    }
  }
}

.arrow-btn-box{
  .mat-mdc-outlined-button{
    --mdc-outlined-button-container-height: 1.75rem;
    --mdc-outlined-button-container-shape: 0.375rem;
    --mat-outlined-button-horizontal-padding: 0;
    --mat-outlined-button-icon-spacing: 0px;
    --mat-outlined-button-icon-offset: 0px;
    line-height: 1.75rem;
    min-width: 2.5rem;
    @include b.flex(row, center, center);

    &:disabled {
      background: b.$disabled-element-bg;
      border: 1px solid b.$disabled-element-borders;
      color: b.$disabled-element-text;
    }

    .mat-mdc-button-touch-target {
      height: 2rem;
    }

    .mat-icon{
      @include b.mat-icon(1.125rem, 1.125rem, 1.125rem, 1.125rem);
    }
  }
}

.menu-open-btn{
  &.mat-mdc-outlined-button{
    --mdc-outlined-button-label-text-size: 0.75rem;
    --mdc-outlined-button-label-text-tracking: 0.025em;
    --mdc-outlined-button-label-text-weight: 500;
    --mdc-outlined-button-container-height: 1.875rem;
    --mdc-outlined-button-container-shape: 0.375rem;
    --mat-outlined-button-horizontal-padding: 0.625rem;
    --mdc-outlined-button-label-text-color: var(--primary-color);
    line-height: 1.875rem;
    min-width: auto;
    @include b.flex(row-reverse, center, center);
    column-gap: 0.375rem;

    .mat-mdc-button-touch-target {
      height: 2rem;
    }

    .mat-icon{
      margin: 0;
      @include b.mat-icon(1.5rem, 1.5rem, 1.5rem, 1.5rem);
    }
  }
}


// ICON BUTTONS
.show-hide-columns-wrapper,
.report-buttons,
.actions-cell{
  .mat-mdc-icon-button{
    --mdc-icon-button-state-layer-size: 1.75rem;
    padding: 0px;
    @include b.flex(row, center, center);

    .mat-mdc-button-touch-target{
      width: 1.75rem;
      height: 1.75rem;
    }

    .mat-icon,
    .material-icons,
    .material-icons-outlined{
      @include b.mat-icon(1.375rem, 1.375rem, 1.375rem, 1.375rem);
    }

    &.btn-link{
      color: var(--table-link);
    }

    &.btn-show{
      color: var(--alert-info);
    }

    &.add-btn{
      color: var(--custom-green);
    }

    &.btn-file{
      color: var(--focus-color);

      .material-icons-outlined{
        width: 1.0675rem!important;
        height: 1.0675rem!important;
        font-size: 1.0675rem!important;
        line-height: 1.0675rem!important;
      }
    }


    &:disabled {
      color: b.$disabled-element-text;
    }
  }
}

.cell-actions,
.dynamic-box{
  .mat-mdc-icon-button{
    --mdc-icon-button-state-layer-size: 1.5rem;
    padding: 0px;
    @include b.flex(row, center, center);

    .mat-mdc-button-touch-target{
      width: 2rem;
      height: 2rem;
    }

    .mat-icon,
    .material-icons,
    .material-icons-outlined{
      @include b.mat-icon(1.25rem, 1.25rem, 1.25rem, 1.25rem);
    }

    &.btn-edit{
      color: indigo;

      &:disabled {
        color: b.$disabled-element-text;
      }
    }
  }
}

.header-add-btn,
.footer-actions{
  .mat-mdc-icon-button{
    --mdc-icon-button-state-layer-size: 1.75rem;
    padding: 0;
    @include b.flex(row, center, center);

    .mat-mdc-button-touch-target{
      width: 2rem;
      height: 2rem;
    }
  }
}

.footer-actions{
  padding: 0.25rem;
}

.table-posting-order{
  .actions-cell{
    .mat-mdc-icon-button{
      --mdc-icon-button-state-layer-size: 1.375rem;
      padding: 0px;
      @include b.flex(row, center, center);

      .mat-mdc-button-touch-target{
        width: 1.5rem;
        height: 1.5rem;
      }

      .mat-icon{
        @include b.mat-icon(1rem, 1rem, 1rem, 1rem);
      }

      &.btn-link{
        color: var(--table-link);
      }
    }
  }
}

.shop-card{
  .article-controls{
    .mat-mdc-icon-button{
      --mdc-icon-button-state-layer-size: 2.5rem;
      padding: 0px;
      @include b.flex(row, center, center);

      .mat-mdc-button-touch-target{
        width: 2.5rem;
        height: 2.5rem;
      }

      .mat-icon{
        @include b.mat-icon(1.75rem, 1.75rem, 1.75rem, 1.75rem);
      }
    }
  }
}

.report-buttons.file-upload-btn{
  .mat-icon{
    @include b.mat-icon(1.5rem, 1.5rem, 1.5rem, 1.5rem);
  }
}

.mat-select-search-clear{
  &.mat-mdc-icon-button{
    --mdc-icon-button-state-layer-size: 1.25rem;
    padding: 0px;
    right: 0.5rem;
    @include b.flex(row, center, center);

    .mat-mdc-button-touch-target{
      width: 2rem;
      height: 2rem;
    }

    .mat-icon{
      width: 1rem;
      height: 1rem;
      font-size: 1rem;
    }
  }
}

.custom-suffix-btn{
  &.mat-mdc-icon-button{
    --mdc-icon-button-state-layer-size: 1.5rem;
    padding: 0px;
    right: 0.5rem;
    @include b.flex(row, center, center);

    .mat-mdc-button-touch-target{
      width: 2rem;
      height: 2rem;
    }

    .mat-icon{
      width: 1.125rem;
      height: 1.125rem;
      font-size: 1.125rem;
    }
  }
}

.activities-btn-box{
  .mat-mdc-icon-button{
    --mdc-icon-button-state-layer-size: 2rem;
    padding: 0px;
    @include b.flex(row, center, center);

    .mat-mdc-button-touch-target{
      width: 2rem;
      height: 2rem;
    }

    .mat-icon{
      @include b.mat-icon(1.5rem, 1.5rem, 1.5rem, 1.5rem);
    }
  }
}
.favorite-btn{
  &.mat-mdc-icon-button{
    --mdc-icon-button-state-layer-size: 1.5rem;
    padding: 0px;
    @include b.flex(row, center, center);

    .mat-mdc-button-touch-target{
      width: 1.75rem;
      height: 1.75rem;
    }

    .mat-icon{
      @include b.mat-icon(1.25rem, 1.25rem, 1.25rem, 1.25rem);
    }
  }
}

.custom-mat-table{
  .mat-mdc-table{
    .mat-mdc-cell.mat-column-actions{
      & > span{
        @include b.flex(row, center, center);
        column-gap: 0.75rem;
      }
      .mat-mdc-icon-button{
        --mdc-icon-button-state-layer-size: 1.5rem;
        padding: 0px;
        @include b.flex(row, center, center);

        .mat-mdc-button-touch-target{
          width: 2rem;
          height: 2rem;
        }

        .mat-icon,
        .material-icons,
        .material-icons-outlined{
          width: 1.25rem;
          height: 1.25rem;
          font-size: 1.25rem;
        }

        &.btn-show{
          color: var(--alert-info);
          margin: 0 auto;
        }

        &.btn-edit{
          color: indigo;
          margin: 0 auto;
        }

        &:disabled {
          color: b.$disabled-element-text;
        }
      }
    }
  }
}


// Mini Fab Buttons
.filter-actions{
  .mat-mdc-mini-fab{
    --mdc-fab-small-container-color: var(--primary-color);
    --mat-fab-small-foreground-color: var(--light-color-1);
    --mdc-fab-small-container-elevation-shadow: none;
    --mdc-fab-small-focus-container-elevation-shadow: none;
    --mdc-fab-small-hover-container-elevation-shadow: none;
    --mdc-fab-small-pressed-container-elevation-shadow: none;

    &.mdc-fab--mini {
      width: 1.75rem;
      height: 1.75rem;
    }

    .mat-mdc-button-touch-target{
      width: 2rem;
      height: 2rem;
    }

    .mdc-button__label{
      display: flex;
      justify-content: center;
      align-items: center;

      .material-icons-outlined{
        @include b.mat-icon(1rem, 1rem, 1rem, 1rem);
      }
    }

    .mat-icon{
      @include b.mat-icon(0.9375rem, 0.9375rem, 0.9375rem, 1rem);
    }
  }
}

.add-dynamic-row-box,
.dynamic-card-row,
.dynamic-box,
.remove-box{
  .mat-mdc-mini-fab{
    --mat-fab-small-foreground-color: var(--light-color-1);
    --mdc-fab-small-container-elevation-shadow: none;
    --mdc-fab-small-focus-container-elevation-shadow: none;
    --mdc-fab-small-hover-container-elevation-shadow: none;
    --mdc-fab-small-pressed-container-elevation-shadow: none;

    &.mdc-fab--mini {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:disabled {
      --mdc-fab-small-container-color: var(--disabled-element-bg);
      background: b.$disabled-element-bg;
      border: 1px solid b.$disabled-element-borders;
      color: b.$disabled-element-text;
    }

    .mat-mdc-button-touch-target{
      width: 2rem;
      height: 2rem;
    }

    .mat-icon{
      @include b.mat-icon(1rem, 1rem, 1rem, 1.0625rem);
    }
  }
}

.remove-box.edit-box{
  .mat-mdc-mini-fab{
    --mat-fab-small-foreground-color: var(--light-color-1);
    --mdc-fab-small-container-elevation-shadow: none;
    --mdc-fab-small-focus-container-elevation-shadow: none;
    --mdc-fab-small-hover-container-elevation-shadow: none;
    --mdc-fab-small-pressed-container-elevation-shadow: none;

    &.mdc-fab--mini {
      width: 1.5rem;
      height: 1.5rem;
    }

    .mat-mdc-button-touch-target{
      width: 2rem;
      height: 2rem;
    }

    .mat-icon{
      @include b.mat-icon(0.875rem, 0.875rem, 0.875rem, 0.9375rem);
    }
  }
}












