// CUSTOM APP THEME
@use "/src/assets/scss/themes/" as theme;

/* ****  VARIABLES, MIXINS, and HELPERS **** */
@use "/src/assets/scss/base/" as b;

/* **** CUSTOM MATERIAL UI COMPONENTS **** */
@use "/src/assets/scss/material/" as material;

// BOOTSTRAP
@import "node_modules/bootstrap/scss/bootstrap.scss";

// LEAFLET STYLES
@import "leaflet/dist/leaflet.css";

$storageUrl: "https://s3.eu-central-1.amazonaws.com/storage-opr-man.net/";
$flagsUrl: $storageUrl + "flagIcons/";

body {
  // THEME COLORS
  --primary-color: #0f7dec;
  --secondary-color: #f0661a;
  --error-color: #e53935;

  // HEADER COLORS
  --header-bg: #f8f8f8;
  --header-text: #444444;
  --header-icons: #808080;
  --header-borders: #3d3d3d;

  // SIDEBAR COLORS
  --sidebar-bg: #5a5a5d;
  --sidebar-hover: #4f4f4f;
  --sidebar-active: #f7e708;
  --sidebar-text: #ffffff;
  --sidebar-borders: #888888;

  // MAIN CONTENT COLORS
  --page-bg: #f8f8f8;
  --page-text: #444444;
  --page-borders: #c8c8c8;
  --highlight-bg: #f4f4f4;
  --table-header: #EDEDED;
  --table-header-text: #444444;
  --table-link: #0a60f3;
  --table-footer: #F4F4F4;
  --table-footer-text: #444444;
  --table-row-highlight: #87bef6;
  --card-header: #E3E6E9
  --card-body: #ffffff;
  --card-text: #444444;
  --disabled-element-bg: rgba(0, 0, 0, 0.12);
  --disabled-element-borders: rgba(0, 0, 0, 0.12);
  --disabled-element-text: #777777;

  // ALERT COLORS
  --alert-success: #2fda24;
  --alert-warning: #f7e708;
  --alert-danger: #e53935;
  --alert-info: #0f7dec;

  // CUSTOM COLORS
  --light-color: #ffffff;
  --extra-light-gray-color: #f8f8f8;
  --light-gray-color: #cccccc;
  --gray-color: #808080;
  --dark-color: #2d2d2d;
  --dark-btn-bg: #4a5962;
  --custom-green: #2fda24;

  // PASTEL BG
  --jungle-mist: #A1CDCE;
  --light-steel-blue: #84A6D6;
  --salmon: #F9968B;
  --tomato: #F27348;
  --gold: #F4C815;
  --dark-sea-green: #8DA47E;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--page-bg);
  scrollbar-color: var(--primary-color) var(--extra-light-gray-color);
}

#pms-global-spinner {
  background-color: rgba(204, 204, 204, 0.6);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  display: none;
}

#pms-global-spinner .spinner-container {
  position: relative;
  width: 300px;
  height: 200px;
}

#pms-global-spinner .spinner-container .mat-progress-spinner circle,
#pms-global-spinner .spinner-container .mat-spinner circle {
  stroke: #0f7dec !important;
  stroke-width: 5px !important;
}

.alert.alert-success,
.alert.alert-info,
.alert.alert-dark,
.alert.alert-danger,
.alert.alert-warning {
  font-size: 13px;
  width: auto;
  min-width: 300px;
  margin-top: 0.5rem;
}

#alert-wrapper #alert-block {
  position: fixed;
  z-index: 9999;
  top: 35px;
  right: 50%;
  min-width: 20%;
  width: auto;
  text-align: center;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.none {
  display: none;
}

/** Klase za zastavice u jezicima*/

.en {
  background: url($flagsUrl + "en.png") left / contain no-repeat;
  background-size: 22px 14px;
}

.sr {
  background: url($flagsUrl + "sr.png") left / contain no-repeat;
  background-size: 22px 14px;
}

.sr-Cyrl {
  background: url($flagsUrl + "sr.png") left / contain no-repeat;
  background-size: 22px 14px;
}

.section-content{
  width: 100%;
  height: calc(100vh - 40px);
  overflow-x: hidden;
  overflow-y: auto;
}

.fixed-page-header {
  position: fixed;
  top: 2.5rem;
  left: 0px;
  width: 100%;
  height: calc(100vh - 2.5rem);
  padding: 1rem;
}
.relative-page-header {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  padding: 1rem 0.25rem 0;
}
.page-header-title {
  font-size: 1.125rem;
  line-height: 1;
  margin-bottom: 0.75rem;
  color: var(--page-text);
}

iframe {
  border-color: var(--light-gray-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.375rem;
}

.iframe-wrapper {
  @include b.flex(row, center, center);
  width: 100%;
  height: calc(100vh - 130px);
}

#canvasBlock canvas {
  margin: 0px auto !important;
}

.closeIconBold{
  margin-left: auto;
}

.formHeading{
  @include b.flex(row, flex-start, center);
  padding: 0.25rem 0.75rem;

  .modalHeading{
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 0.25rem;
  }
}

.input-methods{
  @include b.flex(row, flex-start, center);
  column-gap: 1rem;
  margin-bottom: 0.25rem;

  label.sub-title {
      display: inline-block;
      font-size: 0.8125rem;
      font-weight: 500;
      margin-bottom: 0;
  }

  .mat-mdc-radio-group{
    @include b.flex(row, flex-start, center);
    column-gap: 1rem;
  }
}

.table-action-box {
  @include b.flex(row, space-between, flex-start);
  column-gap: 1.5rem;

  .search-box{
    display: block;
    width: 14rem;
  }
  .action-box{
    display: block;
    margin-top: 1px;
  }
}

// MRP PAGE
.header-page {
  min-height: calc(100vh - 40px);
  background: var(--jungle-mist);

  .page-header-title{
    color: var(--extra-light-gray-color);
    font-weight: 500;
  }

  .header-button-box{
    @include b.flex(row, space-between, flex-start);
    border-bottom: none;
    background-color: rgba(0,0,0,0.3);
    padding: 0.5rem;

    .header-buttons .mat-mdc-outlined-button,
    .header-info-and-help .mat-mdc-outlined-button{
      border: 1px solid var(--extra-light-gray-color);
      color: var(--extra-light-gray-color);
    }

    .header-buttons{
      @include b.flex(row, flex-start, flex-start);
      flex: 1;
      flex-wrap: wrap;
      column-gap: 1rem;
    }
    .header-buttons button:not(:last-child) {
      margin-right: 0px;
    }
    .header-info-and-help{
      @include b.flex(row, flex-end, flex-start);
      column-gap: 1rem;

      button{
        @include b.text-ellipsis;
      }
    }
  }
}

.tabs-page {
  min-height: calc(100vh - 40px);

  .page-header-title{
    color: var(--page-text);
    font-weight: 500;
  }

  .header-buttons .mat-mdc-outlined-button,
  .header-info-and-help .mat-mdc-outlined-button{
    &:not(:disabled){
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }

  .header-button-box{
    @include b.flex(row, space-between, flex-start);
    border-bottom: none;
    padding: 0;

    .header-buttons{
      @include b.flex(row, flex-start, flex-start);
      flex: 1;
      flex-wrap: wrap;
      column-gap: 1rem;

    }
    .header-buttons button:not(:last-child) {
      margin-right: 0px;
    }
    .header-info-and-help{
      @include b.flex(row, flex-end, flex-start);
      column-gap: 1rem;

      button{
        @include b.text-ellipsis;
      }
    }
  }
}

//MAT PASSWORD STRENGTH CUSTOM COLOR
mat-password-strength .mat-progress-bar-fill::after {
  background-color: var(--custom-green);
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: whitesmoke;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: whitesmoke;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #5ff155;
}

// POPOVER BOTTOM
.passwordPopoverBottom .popover-header {
  font-size: 14px;
}
.passwordPopoverBottom .popover-body {
  font-size: 12px;
}

.iframe-holder {
  width: 90%;
  max-width: 1100px;
  height: 95%;
  background-color: white;
  overflow: hidden;
  border-radius: 4px;
}




////////////////////////////////////////////////////////////////////

/* ***** ONLY RESPONSIVE ***** */

////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1400px) {
  .download-maps .mat-checkbox-label {
    max-width: 275px;
  }
}
@media screen and (max-width: 1220px) {
  .backward-info .selectedItemTable {
    margin-top: 48px;
  }
  .download-maps .mat-checkbox-label {
    max-width: 230px;
  }
}
@media screen and (max-width: 1180px) {
  .dashboard-content.source-destination.all-header-buttons
    .mat-tab-group-dynamic-height
    .mat-tab-body-content {
    height: calc(100vh - 240px);
  }
}
@media screen and (max-width: 1050px) {
  .dashboard-content.source-destination
    .mat-tab-group-dynamic-height
    .mat-tab-body-content {
    height: calc(100vh - 230px);
  }
  .download-maps .mat-checkbox-label {
    max-width: 215px;
  }
}

@include b.laptop {
  .page-header-title {
    font-size: 1.25rem;
  }
  .header-button-box {
    & .mat-stroked-button {
      padding: 0px 12px;
      line-height: 22px;
      font-size: 13px;
    }

    & .mat-stroked-button .mat-icon {
      height: 15px;
      width: 15px;
      font-size: 15px;
      line-height: 13px;
    }
  }
  .help-block {
    & .mat-stroked-button {
      padding: 0px 12px;
      line-height: 22px;
      font-size: 13px;
    }

    & .mat-stroked-button .mat-icon {
      height: 15px;
      width: 15px;
      font-size: 15px;
      line-height: 13px;
    }
  }
  .content-wrapper .mat-tab-label,
  .content-wrapper .mat-tab-link {
    font-size: 13px;
  }
  .header-button-box.distribution-box .header-form .mat-form-field {
    line-height: 0.78;
  }
  .reducedForm.header-form .mat-form-field-appearance-outline .mat-select {
    transform: translateY(-5px);
  }
  .reducedForm.header-form
    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    top: 23px;
  }
  .header-form .mat-select-value-text {
    font-size: 12px !important;
  }
  .custom-dialog .map,
  .mapModal .map {
    min-height: 280px;
    max-height: 324px;
  }
  .current-location,
  .custom-dialog .map + span,
  .mapModal .map + span {
    font-size: 13px;
  }
  .download-maps .mat-checkbox-label {
    max-width: 350px;
  }
  .threeDtable .tableWrapper {
    width: 70%;
  }
}
@media screen and (max-width: 840px) {
  .download-maps .mat-checkbox-label {
    max-width: 325px;
  }
}
@include b.tablet {
  .page-header-title {
    font-size: 1.1rem;
  }
  .header-button-box {
    & .mat-stroked-button {
      padding: 0px 10px;
      line-height: 21px;
      font-size: 12px;
    }
  }
  .help-block {
    & .mat-stroked-button {
      padding: 0px 10px;
      line-height: 21px;
      font-size: 12px;
    }
  }
  .content-wrapper .mat-tab-label,
  .content-wrapper .mat-tab-link {
    font-size: 12px;
  }
  .custom-dialog .mrp-table .tableWrapper {
    width: 665px;
    padding-right: 20px;
    overflow-x: auto;
  }
  .custom-dialog .mrp-table .tableWrapper .actionsContainer {
    flex-wrap: wrap;
    & .paginations {
      order: 1;
      width: 100%;
    }
    & .actions {
      order: 2;
      width: 100%;
      margin: 0px;
    }
  }
  .custom-dialog .pfsp-table .tableWrapper {
    width: 579px;
    padding-right: 20px;
    overflow-x: auto;
  }
  .custom-dialog .cfp-table .tableWrapper {
    width: 615px;
    padding-right: 20px;
    overflow-x: auto;
  }
  .header-button-box.distribution-box .header-form .mat-form-field {
    line-height: 0.68;
  }
  .dashboard-content.source-destination
    .mat-tab-group-dynamic-height
    .mat-tab-body-content {
    height: calc(100vh - 260px);
  }
  .dashboard-content.source-destination.all-header-buttons
    .mat-tab-group-dynamic-height
    .mat-tab-body-content {
    height: calc(100vh - 265px);
  }
  .custom-dialog .map,
  .mapModal .map {
    min-height: 235px;
    max-height: 279px;
  }
  .forward-info .selectedItemTable {
    margin-top: 10px;
  }
  .backward-info .selectedItemTable {
    margin-top: 15px;
  }
  .download-maps .mat-checkbox-inner-container {
    margin: 0;
    transform: translateY(3px);
    margin-right: 8px;
    vertical-align: top;
  }
  .download-maps .mat-checkbox-label {
    max-width: 680px;
    text-overflow: initial;
    overflow: initial;
    white-space: pre-wrap;
    line-height: 1.6;
  }
  .forward-info .selectedItemTable {
    width: 99%;
  }
  .backward-info .selectedItemTable {
    width: 100%;
  }
  .geo-location-tab-content
    .mat-tab-group-dynamic-height
    .mat-tab-body-content {
    padding-right: 15px;
  }
  .threeDtable .tableWrapper .actionsContainer .paginations {
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .mat-form-field.mat-form-field-appearance-outline {
      order: 2;
    }
  }
}

@media screen and (max-width: 650px) {
  .custom-dialog .overview-table .tableWrapper {
    width: 490px;
    padding-right: 20px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 640px) {
  .custom-dialog .distribution-table .tableWrapper .actionsContainer {
    @include b.flex(row, space-between, flex-start);
    flex-wrap: nowrap;

    & .actions {
      width: 70px;
      order: 1;
      margin-top: 8px;
    }

    & .paginations {
      width: calc(100% - 80px);
      order: 2;
      flex-wrap: wrap;
      justify-content: flex-end;

      & .mat-form-field.mat-form-field-appearance-outline {
        order: 2;
        margin-top: 10px;
      }

      & .mat-paginator-page-size {
        transform: translateX(16px);
      }
    }
  }
}
@include b.mobile {
  .page-header-title {
    font-size: 1rem;
  }
  .header-button-box {
    & .mat-stroked-button {
      padding: 0px 8px;
      line-height: 20px;
      font-size: 11px;
    }

    & .mat-stroked-button .mat-icon {
      height: 14px;
      width: 14px;
      font-size: 14px;
      line-height: 12px;
    }
  }
  .help-block {
    & .mat-stroked-button {
      padding: 0px 8px;
      line-height: 20px;
      font-size: 11px;
    }

    & .mat-stroked-button .mat-icon {
      height: 14px;
      width: 14px;
      font-size: 14px;
      line-height: 12px;
    }
  }
  .header-button-box.distribution-box {
    align-items: flex-start;
  }
  .header-form .mat-select-value-text {
    font-size: 11px !important;
  }
  .dashboard-content.source-destination
    .mat-tab-group-dynamic-height
    .mat-tab-body-content,
  .dashboard-content.source-destination.all-header-buttons
    .mat-tab-group-dynamic-height
    .mat-tab-body-content {
    height: calc(100vh - 290px);
  }
  .custom-dialog .map,
  .mapModal .map {
    min-height: 210px;
    max-height: 234px;
  }
  .current-location,
  .custom-dialog .map + span,
  .mapModal .map + span {
    font-size: 12px;
  }
  .login-form .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0;
    line-height: 1.5;
    transform: translateY(-5px);
  }
  .login-form
    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    top: 26px;
  }
  .forward-info .selectedItemTable {
    width: 100%;
  }
  .passwordPopoverBottom .popover-header {
    font-size: 12px;
  }
  .passwordPopoverBottom .popover-body {
    font-size: 11px;
  }
  .iframe-wrapper {
    height: calc(100vh - 160px);
  }
  .reducedForm
    .mat-form-field-appearance-outline
    .mat-form-field-subscript-wrapper {
    padding: 0;
  }
  .threeDtable .tableWrapper {
    width: 100%;
    padding-right: 5px;
  }
  .threeDtable .tableWrapper .actionsContainer .paginations {
    .mat-form-field.mat-form-field-appearance-outline {
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 500px) {
  .passwordPopoverBottom.popover {
    max-width: 100px;
    max-height: 250px;
    overflow-y: auto;
  }
}

@include b.sm-mobile {
  .custom-dialog .distribution-table .tableWrapper {
    width: 325px;
    padding-right: 20px;
    overflow-x: auto;
  }
  .all-buttons .mat-button-wrapper,
  .all-buttons-and-overview .mat-button-wrapper {
    @include b.text-ellipsis;
    display: block;
  }
  .reducedForm.order-form .mat-form-field .mat-form-field-wrapper {
    padding-bottom: 10px;
  }
  .reducedForm.order-form
    .mat-form-field.requiredField
    .mat-form-field-wrapper {
    padding-bottom: 15px;
  }
  .dashboard-content.source-destination
    .mat-tab-group-dynamic-height
    .mat-tab-body-content,
  .dashboard-content.source-destination.all-header-buttons
    .mat-tab-group-dynamic-height
    .mat-tab-body-content {
    height: calc(100vh - 310px);
  }
  .custom-dialog .map,
  .mapModal .map {
    min-height: 100px;
    max-height: 209px;
  }
  .reducedForm
    .search-box
    .mat-form-field.requiredField
    .mat-form-field-wrapper {
    padding-bottom: 12px;
  }
}
