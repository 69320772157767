@use "/src/assets/scss/base/" as b;

.custom-table-tooltip {
  position: relative;
  display: inline-block !important;
  cursor: initial;
  opacity: 1;
  text-align: center;
  background-color: b.$page-bg;
  color: b.$page-text;
  border: 1px solid b.$page-borders;
  border-radius: 4px;
  padding: 0 8px;
  min-width: 40px;
  line-height: 20px;
  font-size: 10px;
}

.custom-table-tooltip .text-holder {
  display: flex !important;
  justify-content: center;
  align-items: center;
  column-gap: 4px;

  .material-icons {
    width: 14px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
  }
  .material-icons.transformed {
    transform: translateY(1px);
  }

  .title {
    display: inline-block;
    max-width: 85px;
    text-overflow: ellipsis;
    // overflow: hidden;
    white-space: nowrap;

    &.view-pos{
      max-width: 7rem;
    }
  }
}

.custom-table-tooltip .tooltiptext {
  visibility: hidden;
  background-color: b.$page-bg;
  color: b.$page-text;
  text-align: center;
  padding: 0.5rem;
  border: 1px solid b.$page-borders;
  border-radius: 6px;
  top: 0;
  position: absolute;
  z-index: 1;
  font-size: 10px;
  font-weight: 400;
  display: grid;
  column-gap: 15px;
  row-gap: 0.25rem;
}

.custom-table-tooltip .tooltiptext.partner-to-partner-type {
  width: 270px;
  left: -275px;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
}
.custom-table-tooltip .tooltiptext.article-to-article-type {
  width: 445px;
  left: -450px;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
}
.custom-table-tooltip .tooltiptext.merchandizing-report {
  width: 375px;
  left: -380px;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
}
.custom-table-tooltip .tooltiptext.overview-driver-work-orders {
  width: 375px;
  left: 100px;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
}

.custom-table-tooltip:hover {
  cursor: pointer;
}
.custom-table-tooltip:hover .tooltiptext {
  visibility: visible;
}
.custom-table-tooltip .tooltiptext p {
  margin-bottom: 0px;
  text-align: left;
  line-height: 1.5;
}
.custom-table-tooltip .tooltiptext p.text-bold {
  font-weight: 500;
  text-align: center;
  color: b.$error-color;
}
