@use "/src/assets/scss/base/" as b;

// User panel
.user-toolbar {
  .mat-toolbar-single-row {
    height: 40px;
    font-size: 13px;
    text-align: center;
    background: var(--highlight-bg);
    color: var(--page-text);
    padding: 0 0.75rem;
  }
  .mat-toolbar-single-row .mat-icon{
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: var(--primary-color);
    margin-right: 0.375rem;
  }
  .mat-mdc-menu-content {
    padding: 0;

    .mat-mdc-menu-item {
      min-height: 2.5rem;
      padding: 0 0.75rem;

      .mat-mdc-menu-item-text {
        display: flex;
        align-items: center;
        column-gap: 0.625rem;
        --mat-menu-item-label-text-size: 0.8125rem;

        img{
          display: block;
          width: 1.75rem;
          aspect-ratio: 1;
          outline: 2px solid var(--primary-color);
          outline-offset: 2px;
          height: auto;
          border-radius: 50%;
        }
      }


      .mat-icon {
        margin-right: 0.375rem;
        width: 1.125rem;
        height: 1.125rem;
        font-size: 1.125rem;
        line-height: 1rem;
        color: var(--primary-color);
      }
    }
  }
}

// Company panel
.company-toolbar {
  max-height: calc(100vh - 125px);
  .mat-toolbar-single-row {
    height: 2.25rem;
    font-size: 0.8125rem;
    text-align: center;
    background: var(--highlight-bg);
    color: var(--page-text);
  }
  .mat-mdc-menu-content {
    padding: 0;

    .mat-mdc-menu-item {
      min-height: 2.25rem;

      &:not(:last-of-type){
        border-bottom: 1px solid var(--page-borders);
      }

      .mat-mdc-menu-item-text {
        --mat-menu-item-label-text-size: 0.75rem;
      }

      span.text-bold{
        font-weight: 500;
      }
    }
  }
}

// Employees panel
.employees-panel {
  &.mat-mdc-menu-panel{
    max-width: 400px;

    .mat-mdc-menu-content {
      padding: 0.5rem 0.75rem 0.75rem;

      .selected-department{
        font-size: 0.875rem;
        margin-bottom: 0.375rem;
      }
    }
  }
}

// Columns panel
.columns-panel{
  &.mat-mdc-menu-panel{
    transform-origin: right top!important;

    .mat-mdc-menu-content {
      padding: 0;

      .mat-mdc-menu-item {
        min-height: 2.25rem;

        &:not(:last-of-type){
          border-bottom: 1px solid var(--page-borders);
        }

        .mat-mdc-menu-item-text {
          --mat-menu-item-label-text-size: 0.75rem;
        }
      }
    }
  }
}

// Date Range and Number Range panels
.date-range-menu,
.number-range-menu{
  &.mat-mdc-menu-panel{
    max-width: 11rem!important;
  }

  .mat-mdc-menu-content {
    padding: 0.375rem 0.5rem;
    overflow: hidden;

    .cta-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1.5rem;

        button.mat-mdc-outlined-button{
          --mdc-outlined-button-label-text-size: 0.75rem;
          --mdc-outlined-button-label-text-tracking: 0.025em;
          --mdc-outlined-button-label-text-weight: 400;
          --mdc-outlined-button-container-height: 1.5rem;
          --mdc-outlined-button-container-shape: 0.25rem;
          --mat-outlined-button-horizontal-padding: 0.625rem;
          line-height: 1.5rem;
          width: calc(50% - 0.75rem);
          min-width: auto;

          &.mat-primary{
            border: 1px solid var(--primary-color);
          }

          &.mat-warn{
            border: 1px solid var(--error-color);
          }
        }
      }
  }

}

// Categorized menu
.categorized-menu {
  &.mat-mdc-menu-panel{
    .mat-mdc-menu-content {
      padding: 0;

      .mat-mdc-menu-item {
        min-height: 2rem;

        .mat-mdc-menu-item-text {
          --mat-menu-item-label-text-size: 0.75rem;
        }
      }
    }
  }

}



