@use "/src/assets/scss/base/" as b;

.custom-tab-group{
  &.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0;
    min-width: 7.5rem;
  }
  .mat-mdc-tab-header {
    --mdc-secondary-navigation-tab-container-height: 2.25rem;

    .mat-mdc-tab {
      --mat-tab-header-label-text-size: 0.8125rem;
      --mat-tab-header-label-text-tracking: 0.025em;
      --mat-tab-header-label-text-line-height: 2.25rem;
      --mat-tab-header-label-text-weight: 500;
      --mat-tab-header-active-label-text-color: var(--primary-color);
      --mat-tab-header-active-focus-label-text-color: var(--primary-color);
      --mat-tab-header-active-hover-label-text-color: var(--primary-color);
    }
  }

  .mat-mdc-tab-body{
    padding: 0.75rem 0;
  }
}

