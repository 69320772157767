$dimensions: (
  "4" : 4px,
  "5" : 5px,
  "8" : 8px,
  "10" : 10px,
  "12" : 12px,
  "15" : 15px,
  "20" : 20px,
  "25" : 25px,
  "30" : 30px,
  "32" : 32px,
  "36" : 36px,
  "40" : 40px,
);

@each $sufix, $value in $dimensions {
  .mt-#{$sufix} {
    margin-top: $value;
  }
  .mb-#{$sufix} {
    margin-bottom: $value;
  }
  .pt-#{$sufix} {
    padding-top: $value;
  }
  .pb-#{$sufix} {
    padding-bottom: $value;
  }
}

@for $i from 30 through 600 {
  .flex-#{$i} {
    flex: 0 0 #{$i}px;
  }
}

@for $i from 20 through 600 {
  .w-min-#{$i} {
    min-width: #{$i}px;
  }
}
