// THEME COLORS
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$error-color: var(--error-color);

// HEADER COLORS
$header-bg: var(--header-bg);
$header-text: var(--header-text);
$header-icons: var(--header-icons);
$header-borders: var(--header-borders);

// SIDEBAR COLORS
$sidebar-bg: var(--sidebar-bg);
$sidebar-hover: var(--sidebar-hover);
$sidebar-text: var(--sidebar-text);
$sidebar-borders: var(--sidebar-borders);

// MAIN CONTENT COLORS
$page-bg: var(--page-bg);
$page-text: var(--page-text);
$page-borders: var(--page-borders);
$highlight-bg: var(--highlight-bg);
$table-header: var(--table-header);
$table-header-text: var(--table-header-text);
$table-link: var(--table-link);
$table-footer: var(--table-footer);
$table-footer-text: var(--table-footer-text);
$table-row-highlight: var(--table-row-highlight);
$card-header: var(--card-header);
$card-body: var(--card-body);
$card-text: var(--card-text);
$disabled-element-bg: var(--disabled-element-bg);
$disabled-element-borders: var(--disabled-element-borders);
$disabled-element-text: var(--disabled-element-text);
$icon-color: var(--icon-color);

// ALERT COLORS
$alert-success: var(--alert-success);
$alert-warning: var(--alert-warning);
$alert-danger: var(--alert-danger);
$alert-info: var(--alert-info);

// CUSTOM COLORS
$light-color: var(--light-color);
$light-gray-color: var(--light-gray-color);
$gray-color: var(--gray-color);
$dark-color: var(--dark-color);
$custom-green: var(--custom-green);

// PASTEL BG
$jungle-mist: var(--jungle-mist);
$light-steel-blue: var(--light-steel-blue);
$salmon: var(--salmon);
$tomato: var(--tomato);
$gold: var(--gold);
$dark-sea-green: var(--dark-sea-green);

// SIDEBAR MAP
$left-sidebar-colors: (
    "bg-color": #5a5a5d,
    "text-color": #ffffff
);

