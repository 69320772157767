@use "/src/assets/scss/base/" as b;

.partners-box-card,
.sponsorship-card {
  &.mat-mdc-card {
    padding: 0.75rem;
    --mdc-elevated-card-container-elevation:  0 3px 1px -2px #0003,
                                              0 2px 2px #00000024,
                                              0 1px 5px #0000001f;


    .mat-mdc-card-content {
      padding: 0;
    }
  }
}

// Statistics block
.statistics-block-container {
  .mat-mdc-card {
    --mdc-elevated-card-container-elevation:  0 3px 1px -2px #0003,
                                              0 2px 2px #00000024,
                                              0 1px 5px #0000001f;
    .mat-mdc-card-header {
      padding: 0.375rem 0;

      .mat-mdc-card-title {
        --mat-card-title-text-line-height: 1.25rem;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }
}

// Customers statistics block

.statistics-block-container {
  @include b.flex(row, space-between, flex-start);
  column-gap: 1rem;
  width: 100%;
  margin: 0.625rem auto;

  .mat-mdc-card {
    flex: 1;
    padding: 0;
    --mdc-elevated-card-container-elevation:  0 3px 1px -2px #0003,
                                              0 2px 2px #00000024,
                                              0 1px 5px #0000001f;

    .mat-mdc-card-header-text {
      --mat-card-title-text-line-height: 1.25rem;
      font-size: 14px;
      font-weight: 400;
      margin: 0 0.5rem;
    }

    .mat-mdc-card-content {
      padding: 0;
      @include b.flex(column, stretch, stretch);

      .blockContent{
        @include b.flex(row, space-between, center);

        &.no-link-block{
          cursor: initial;
          pointer-events: none;
        }
      }

      h3,
      p {
        color: whitesmoke;
        margin: 0;
      }

      h3 {
        font-size: 1rem;
      }

      p{
        font-size: 0.875rem;
      }

      div {
        flex: 1;
        padding: 0.25rem;
        color: whitesmoke;
      }

      &.blue div {
        &:first-child {
          background: #00a8f8;
        }

        &:last-child {
          background: royalblue;
        }
      }

      &.indigo div {
        &:nth-child(1) {
          background: #ff5252;
        }

        &:nth-child(2){
          background: rgba(0, 204, 0, 0.9);
        }

        &:nth-child(3){
          background: #00a8f8;
        }
      }

      &.green div {
        &:nth-child(1) {
          background: #ff5252;
        }

        &:nth-child(2){
          background: rgba(0, 204, 0, 0.9);
        }

        &:nth-child(3){
          background: #00a8f8;
        }
      }
    }
  }

  .mat-mdc-card.active-card {
    background: hotpink;
  }

  .blockContent {
    cursor: pointer;
  }
}

// TYPE-B
.statistic-block-b {
  width: 100%;
  margin-top: 15px;
  @include b.flex(row, space-between, center);
  gap: 40px;

  .block-b {
    flex: 1;
  }
  .upper-row {
    @include b.flex(row, flex-start, center);
    gap: 15px;

    span {
      font-size: 14px;
    }
    .fw-500 {
      font-weight: 500;
    }
  }

  .middle-row {
    @include b.flex(row, space-between, center);
    gap: 15px;
    margin-top: 10px;

    .left-part {
      @include b.flex(column, flex-start, flex-start);
      gap: 1px;
    }

    .right-part {
      @include b.flex(column, flex-start, flex-end);
      gap: 1px;
    }

    p {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0px;
    }
    span {
      font-size: 13px;
    }
  }
  .lower-row {
    margin-top: 20px;

    .mat-progress-bar {
      height: 20px;
    }
  }
}

.home-statistics-wrapper {
  .statistics-block-container {
    margin: 0 auto 0.25rem;

    .mat-mdc-card {
      --mat-card-title-text-line-height: 1.25rem;
      --mdc-elevated-card-container-elevation:  0 3px 1px -2px #0003,
                                                0 2px 2px #00000024,
                                                0 1px 5px #0000001f;

      .mat-mdc-card-content {
        padding: 0;

        h3 {
          //font-size: 0.875rem;
          font-size: 0.8125rem;
          margin-bottom: 0;
        }
        p{
          //font-size: 0.875rem;
          font-size: 0.8125rem;
        }
      }
    }
  }
}

.partner-info-card {
  .mat-mdc-card-header {
    .mat-mdc-card-header-text {
      display: none;
    }

    .mat-mdc-form-field {
      max-width: 158px;
    }
  }
}
